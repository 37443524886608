import React from 'react';
import styles from './index.scss';

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className={styles.mainFooter}>
      <span>
        Copyright by 4 Medicine &copy; Science Wizard {year}
      </span>
    </div>
  );
}