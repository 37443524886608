import React, { useState } from 'react';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { bool, object, string } from 'prop-types';
import axios from 'axios';
import logoImg from '../../images/logo_blue.png';
import CheckboxField from '../CheckboxField';
import InputField from '../InputField';
import Modal from '../Modal';
import PrivacyPolicy from '../PrivacyPolicy';
import styles from './index.module.scss';

function LoginForm(props) {
  const [isShowPassword, SetShowPassword] = useState(false);
  const [isShowModal, ShowModal] = useState(false);
  const {
    errors,
    isSubmitting,
    status,
    touched,
    values: { statute }
  } = props;

  return (
    <div className={styles.main}>
      <div className={styles.mainContainer}>
        <div className={styles.wrapLogin}>

          <Form>
            <h1 className={styles.loginTitle}>Logowanie</h1>
            <div className={styles.loginImgContainer}>
              <img alt="4 Medicine" className={styles.loginImg} src={logoImg} />
            </div>

            <InputField
              errorName={errors.login}
              name="login"
              placeholder="Nazwa użytkownika"
              touched={touched.login}
              type="text"
            />

            <InputField
              errorName={errors.password}
              isShowPassword={isShowPassword}
              name="password"
              placeholder="Hasło"
              toggleShowPassword={() => SetShowPassword(!isShowPassword)}
              touched={touched.password}
              type={isShowPassword ? 'text' : 'password'}
            />

            <InputField
              errorName={errors.department}
              name="department"
              placeholder="Jednostka"
              touched={touched.department}
              type="text"
            />

            <CheckboxField
              checked={statute}
              errorName={errors.statute}
              name="statute"
              onOpenModal={() => ShowModal(true)}
              touched={touched.statute}
              type="checkbox"
            />

            <div className={styles.btnContainer}><button className={styles.submitBtn} disabled={isSubmitting} type="submit">Logowanie</button></div>
            {status ? <div className={styles.mainError}>{status}</div> : null}
            <div className={styles.loginFooter}><a className={styles.link} href="#">Zapomniałeś/aś hasła?</a></div>
          </Form>
          <Modal handleClose={() => ShowModal(false)} isShowModal={isShowModal}>
            <PrivacyPolicy />
          </Modal>
        </div>
      </div>

    </div>
  );
}

const initialValues = {
  department: '',
  login: '',
  password: '',
  statute: false
};

const storedValues = JSON.parse(localStorage.getItem('loginValues'));

export default withFormik({
  handleSubmit(values, { setSubmitting, props: { history }, setStatus }) {
    localStorage.removeItem('session');
    setStatus();

    const {
      department,
      login,
      password
    } = values;

    axios.post(`https://${department}.sciencewizard.pl/dashboardapi/login`, `username=${login}&password=${password}`, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(response => {
      const valuesToStore = {
        ...values,
        password: ''
      };
      if (JSON.stringify(storedValues) !== JSON.stringify(valuesToStore)) {
        localStorage.setItem('loginValues', JSON.stringify(valuesToStore));
        localStorage.setItem('activeUser', JSON.stringify(response.data.user));
      }
      localStorage.setItem('session', JSON.stringify(response.data.SESSION));

      history.push('/slots');
    })
    .catch(error => {
      setSubmitting(false);
      if (error.response && error.response.status === 422 ) return setStatus('Podane dane są błędne');
      if (error.response && error.response.status === 440 ) return setStatus('Sesja wygasła');
      if (error.response && error.response.status === 403 ) return setStatus('Brak dostępu'); 
    });
  },
  mapPropsToValues: () => (storedValues || initialValues),
  validationSchema: Yup.object().shape({
    department: Yup.string().required('Pole wymagane'),
    login: Yup.string().required('Pole wymagane'),
    password: Yup.string()
      .min(8, 'min 8 znaków')
      .required('Pole wymagane'),
    statute: Yup.bool().test(
      'statute',
      'Zaakceptuj regulamin',
      value => value === true
    ).required('You have to agree with our Terms and Conditions!')
  })
})(LoginForm);

LoginForm.defaultProps = {
  errors: null,
  isSubmitting: false,
  status: null,
  touched: null,
  values: null
};

LoginForm.propTypes = {
  errors: object,
  isSubmitting: bool,
  status: string,
  touched: object,
  values: object
};