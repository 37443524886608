import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logoImg from '../../images/logo_blue.png';
import styles from './index.module.scss';

export default function Logo(props) {
  const [isTimer, updateTimer] = useState('0:00');
  const [session, updateSession] = useState(JSON.parse(localStorage.getItem('session')));
  const user = JSON.parse(localStorage.getItem('activeUser'));

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Math.round((new Date()).getTime() / 1000);
      const timeLeft = session.expiration_time - 60 - currentTime;
      const timeLeftMin = Math.floor(timeLeft / 60);
      const timeLeftSec = timeLeft - timeLeftMin * 60;

      updateTimer(`${timeLeftMin}:${timeLeftSec > 9 ? timeLeftSec : '0' + timeLeftSec}`);
      
      if(timeLeft < 0) {
        clearInterval(interval);
        props.redirectToLogin();
        localStorage.removeItem('session');
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [session]);

  const refreshSession = () => {
    const values = JSON.parse(localStorage.getItem('loginValues'));

    axios.post(`http://${values.department}.sciencewizard.pl/dashboardapi/refresh`, `user_id=${session.user_id}&token=${session.token}`, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(response => {
      console.log(response);
      localStorage.setItem('session', JSON.stringify(response.data.SESSION));
      updateSession(response.data.SESSION);
    })
    .catch(error => { console.log(error) });
  }

  return (
    <div className={styles.logoContainer}>
      <div>
        <img alt="4 Medicine" className={styles.logoImg} src={logoImg} />
      </div>
      <div>
        <div className={styles.user}>{user.first_name} {user.last_name}</div>
        <div className={styles.timerContainer}>
            <span className={styles.timer}>{isTimer}</span><button onClick={refreshSession} className={styles.refreshBtn}>Odśwież</button>
          </div>
      </div>
    </div>
  );
}