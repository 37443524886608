import React, { Suspense, useEffect } from 'react';
import axios from 'axios';
import {
  ChartsBox,
  Footer,
  Loading,
  Logo,
  SelectDiscipline,
  SelectEmployee
} from '../../components';
import { Context } from '../../context';
import styles from './index.module.scss';

import { fetchEmployeesAction } from '../../Actions';

export default function Dashboard(props) {
  const session = JSON.parse(localStorage.getItem('session'));
  const values = JSON.parse(localStorage.getItem('loginValues'));

  if (!session || !session.expiration_time) {
    props.history.push("/login")
  };

  useEffect(() => {
    axios
    .post(`http://${values.department}.sciencewizard.pl/dashboardapi/employees`, `user_id=${session.user_id}&token=${session.token}`, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    .then(response => {
      console.log(response.data.data);
    })
  }, []);

  const redirectToLogin = () => {
    props.history.push("/login");
  }

  return (
    <div className={styles.dashboardContainer}>
      <Suspense fallback={<Loading />}>
        <Logo redirectToLogin={redirectToLogin} />
        {/* <SelectEmployee /> */}
        {/* <SelectDiscipline /> */}
        {/* <Suspense fallback={<Loading />}>
          <ChartsBox />
        </Suspense> */}
        <Footer />
      </Suspense>
    </div>
  );
}
