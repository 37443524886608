import React, { useRef } from 'react';
import styles from './index.scss';

import useOutsideClick from './useOutsideClick';

export default function Modal(props) {
  const { handleClose, isShowModal, children } = props;

  const ref = useRef();

  useOutsideClick(ref, () => {
    if (isShowModal) handleClose();
  });

  if (!isShowModal) {
    return null;
  }
  return (
    <div className={styles.modal}>
      <section className={styles.mainModal} ref={ref}>
        <div className={styles.modalContainer}>
          {children}
        </div>
        <span className={styles.closeBtn} onClick={handleClose}>
          <i className='fa fa-times' />
        </span>
      </section>
    </div>
  );
}