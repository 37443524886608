import React from 'react';
import { bool, func, string } from 'prop-types';
import { Field } from 'formik';
import styles from './index.scss';

export default function InputField(props) {
  const {
    errorName,
    isShowPassword,
    name,
    placeholder,
    toggleShowPassword,
    touched,
    type
  } = props;

  return (
    <div className={styles.inputContainer}>
      <Field
        className={styles.inputElement}
        name={name}
        type={type}
      />
      <span className={styles.inputPlaceholder}>{placeholder}</span>
      {errorName && touched ?
        <>
          <span className={styles.error}>
            <i aria-hidden="true" className="fa fa-exclamation-circle" />
          </span>
          <span className={styles.errorDesc}>{errorName}</span>
        </>
        : name === 'password' && (
          <span className={styles.btnShowPassword} onClick={toggleShowPassword}>
            <i className={isShowPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} />
          </span>
        )
      }
    </div>
  );
}
InputField.defaultProps = {
  errorName: null,
  isShowPassword: false,
  toggleShowPassword: null,
  touched: null
};

InputField.propTypes = {
  errorName: string,
  isShowPassword: bool,
  name: string.isRequired,
  placeholder: string.isRequired,
  toggleShowPassword: func,
  touched: bool,
  type: string.isRequired
};