import React from 'react';
import styles from './index.scss';

export default function PrivacyPolicy() {
  return (
    <div className={styles.mainContainer}>
      <h2>Polityka prywatności</h2>
      <h3>Komu powierzam moje dane osobowe?</h3>
      <p>4 Medicine Rek Sp.k. – administrator systemu https://sciencewizard.pl/ odpowiada za przetwarzanie Twoich danych osobowych. Dane osobowe oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej. Przykładowo mogą to być: adres e-mail użytkownika serwisu internetowego, numer IP komputera użytkownika serwisu internetowego lub inne dane które użytkownik serwisu internetowego przekazuje administratorowi tego serwisu, np. poprzez formularze zamieszczone w serwisie. Informujemy, że zbieramy i przetwarzamy dane osobowe użytkowników systemu https://sciencewizard.pl/ (zwanymi dalej „Serwisem”).</p>
      <p>Powierzone nam dane osobowe są bezpieczne. Zobowiązujemy się do ich wykorzystania zgodnie z przepisami ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 2002r. nr 101 poz. 926 z późn. zm.) („Ustawa” ) oraz zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) (zwanymi dalej „RODO”).</p>

      <h3>Czego można się spodziewać po polityce prywatności w zakresie ochrony danych osobowych? Kto jest administratorem moich danych i jak się z nim skontaktować?</h3>
      <p>Dokument ten przedstawia metody oraz zasady przetwarzania danych osobowych użytkowników korzystających z Serwisu należącego do 4 Medicine Rek Sp.k. Dowiesz z niego w jaki sposób będziemy korzystać z danych osobowych, które nam powierzyłeś. Z dokumentu dowiesz się również jakie są Twoje prawa w zakresie przetwarzania danych osobowych i jak się z nami skontaktować.</p>
      <p>Informujemy, że administratorem (administrator to podmiot który w oparciu o Twoją zgodę wyznacza cel i sposób przetwarzania Twoich danych) podanych przez Ciebie danych osobowych jest 4 Medicine Rek Sp.k. w celu:</p>
      <ul>
        <li>administrowania serwisem,</li>
        <li>świadczenie usług drogą elektroniczną,</li>
        <li>utrzymania sesji użytkownika,</li>
        <li>pomiary statystyczne i udoskonalenie usług (cele analityczne).</li>
      </ul>
      <p>Oznacza to, że 4 Medicine Rek Sp.k. będzie przechowywać Twoje dane osobowe i korzystać z nich wyłącznie w celach, na które się zgodziłeś. Spółka działa pod adresem Al. Jerozolimskie 125/127 lok.601B, 02-017 Warszawa oraz pod adresem mailowym office@4medicine.pl. Osobą zajmującą się danymi osobowymi i przedstawicielem jest Pan Bartłomiej Barczyński, z którym możesz skontaktować się pod wskazanym adresem mailowym.</p>
      <p>Dokładamy starań abyś mógł wygodnie zarządzać danymi osobowymi które nam powierzyłeś. Jeśli nie chcesz na przykład otrzymywać od nas wiadomości e-mail wystarczy, że klikniesz w stosowne pole o rezygnacji, które znajduje się w treści każdego maila, którego od nas otrzymasz.</p>

      <h3>Jakie dane są gromadzone, a jakich danych nie gromadzimy?</h3>
      <p>W żadnym wypadku nie będziemy zbierać i przetwarzać danych osobowych wrażliwych: ujawniających pochodzenie rasowe lub etniczne, poglądy polityczne, przekonania religijne lub światopoglądowe, przynależność do związków zawodowych oraz danych genetycznych, danych biometrycznych w celu jednoznacznego zidentyfikowania osoby fizycznej lub danych dotyczących zdrowia, seksualności lub orientacji seksualnej tej osoby.</p>
      <p>Zwyczajowo gromadzimy podstawowe dane które pozwalają nam się kontaktować z naszymi użytkownikami. Są to: imię i nazwisko, adres email, numer telefonu, adres oraz inne dane wskazane w tej polityce prywatności (patrz: pliki cookies). Zbieramy także dane dodatkowe związane z charakterem Systemu, a dotyczące dorobku naukowego. Zawsze będą to jednak takie dane, które dobrowolnie poda nam użytkownik lub które są powszechnie dostępne w globalnych systemach bibliograficznych, na stronach wydawców lub bazach informacyjnych organizacji ponadnarodowych jak np. UE, OECD, WHO itp.</p>

      <h3>Jaka jest podstawa prawna przetwarzania danych i jak długo dane będą przetwarzane?</h3>
      <p>Będziemy przetwarzać Twoje dane tylko w takich celach na jakie zgodziłeś/aś się poprzez kliknięcia w odpowiednie pola formularza zamieszczonego w Serwisie lub w inny wyraźny sposób. Podstawą prawną przetwarzania Twoich danych osobowych jest Twoja zgoda na przetwarzanie danych lub wymóg realizacji usługi (np. zamówienie Produktu) którą u nas zamówiłeś (stosownie do artykułu 6 ust. 1 lit a i b Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) - RODO.</p>
      <p>Informujemy, że podanie nam danych osobowych nie jest wymogiem ustawowym oraz że nie jesteś zobowiązany do ich podania. Podanie danych osobowych w celach</p>
      <ul>
        <li>administrowania serwisem,</li>
        <li>świadczenie usług drogą elektroniczną,</li>
        <li>utrzymania sesji użytkownika,</li>
        <li>pomiary statystyczne i udoskonalenie usług (cele analityczne),</li>
      </ul>
      <p>jest dobrowolne i nie uzależniamy wykonania jakichkolwiek usług od uzyskania Twojej zgody na korzystania z Twoich danych w w/w celach. Jeżeli korzystasz z naszego Systemu lub usług to podanie danych jest konieczne dla wykonania umowy, w celu realizacji danej usługi lub w celu zapewnienia funkcjonalności Systemu.</p>
      <p>O ile udzieliłeś/aś odpowiedniej zgody na przetwarzanie danych w w/w celach to Twoje dane będą przetwarzane stale, chyba że cofniesz swoją zgodę na ich wykorzystywanie. Jeśli nie wyraziłeś/aś tej zgody będziemy przetwarzać Twoje dane tylko przez czas świadczenia przez nas usługi. Niemniej jednak możemy przechowywać Twoje dane po cofnięciu przez Ciebie zgody lub po zakończeniu świadczenia usług na Twoją rzecz jeśli to przetwarzanie jest uzasadnione przepisami prawa (np. dla celów księgowych i podatkowych) lub przewidujemy, że archiwizacja Twoich danych jest konieczna do ochrony naszych praw.</p>

      <h3>Metody i cele przetwarzania danych</h3>
      <p>Zbieramy dane osobowe dostarczone przez użytkowników w sposób określony powyżej, drogą elektroniczną, a ich przetwarzanie odbywa się w sposób elektroniczny lub manualny. Korzystamy również z wysyłki automatycznej korespondencji elektronicznej.</p>
      <p>Wykorzystujemy podane przez Ciebie informacje dla następujących celów:</p>
      <ul>
        <li>tych nieodłącznie związanych z realizacją i świadczeniem usług w ramach Serwisu;</li>
        <li>administrowania serwisem;</li>
        <li>w celu weryfikacji jakości świadczonych usług drogą elektroniczną;</li>
        <li>do pomiarów statystycznych i udoskonalania usług (cele analityczne).</li>
      </ul>
      <p>Dane osobowe udostępnione przez użytkowników, a następnie zebrane w sposób opisany powyżej, umożliwiają identyfikację użytkowników jedynie przez 4 Medicine Rek Sp.k. Dane mogą zostać przekazane osobom trzecim, tylko i wyłącznie w przypadku gdy korzystamy z usług podwykonawców, szczególnie tych którzy w naszym imieniu i na naszą rzecz zarządzają naszymi bazami danych. Podmioty te nie są administratorami danych, a powierzone dane wykorzystuję tylko w celach wskazanych przez nas i wynikających z powyżej opisanych podstaw. Dane wynikające ze zbierania tzw. cookies są przetwarzane przez naszego zaufanego partnera MKSYS Systemy Serwery.</p>

      <h3>Jakie są Twoje prawa związane z przetwarzaniem danych osobowych?</h3>
      <p>Przysługują Ci następujące prawa w związku z przetwarzaniem przez 4 Medicine Rek Sp.k. Twoich danych osobowych:</p>
      <ul>
        <li>prawo dostępu do Twoich danych, w tym uzyskania kopii danych,</li>
        <li>prawo żądania sprostowania danych</li>
        <li>prawo do usunięcia danych (w określonych sytuacjach),</li>
        <li>prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych,</li>
        <li>prawo do ograniczenia przetwarzania danych.</li>
      </ul>
      <p>Jeżeli Twoje dane są przetwarzane na podstawie zgody możesz dodatkowo skorzystać z poniższych praw:</p>
      <ul>
        <li>prawo do wycofania zgody w zakresie w jakim są przetwarzane na tej podstawie. Wycofanie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.</li>
      </ul>
      <p>Jeżeli Twoje dane są przetwarzane na podstawie zgody lub w ramach świadczonej usługi (dane są niezbędne w celu świadczenia usługi) możesz dodatkowo skorzystać z poniższych praw:</p>
      <ul>
        <li>prawo do przenoszenia danych osobowych, tj. do otrzymania od administratora Twoich danych osobowych, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Możesz przesłać te dane innemu administratorowi danych.</li>
      </ul>
      <p>W celu skorzystania z powyższych praw należy skontaktować się z administratorem lub z Inspektorem ochrony danych (dane poniżej).</p>

      <h3>Czy istnieje wymóg podania danych?</h3>
      <p>Podanie danych osobowych jest dobrowolne jednak niepodanie danych oznaczonych jako niezbędne do świadczenia usług na Twoją rzecz uniemożliwi ich świadczenie.</p>

      <h3>Jak zmienić lub usunąć informacje?</h3>
      <p>4 Medicine Rek Sp.k. to podmiot, który będzie przechowywać Twoje dane osobowe i korzystać z nich wyłącznie w celach na które się zgodziłeś. Spółka działa pod adresem Al. Jerozolimskie 125/127 lok.601B, 02-017 Warszawa oraz pod adresem mailowym office@4medicine.pl.</p>
      <p>Wyznaczyliśmy inspektora ochrony danych dla Spółki. Jest to osoba, z którą można się kontaktować we wszystkich sprawach dotyczących przetwarzania danych osobowych oraz korzystania z praw związanych z tym przetwarzaniem. Jeżeli chcesz skontaktować się z Inspektorem ochrony danych skorzystaj z danych kontaktowych wskazanych poniżej:</p>
      <p>Adres korespondencyjny: 4 Medicine Rek Sp.k / IODO / Al. Jerozolimskie 125/127 lok.601B, 02-017 Warszawa</p>
      <p>E-mail: iodo@4medicine.pl</p>
      <p>Adres ten służy temu abyś mógł z nami kontaktować się w sprawie swoich danych oraz abyś mógł realizować swoje prawa związane z udostępnionymi nam danymi. Masz prawo do żądania od nas dostępu do swoich danych osobowych (poinformujemy Cię jakimi Twoimi danymi się posługujemy), ich sprostowania (jeśli zechcesz poprawimy Twoje dane), usunięcia lub ograniczenia przetwarzania (możesz wybrać dane które będziemy przetwarzać lub żądać abyśmy wszystkie Twoje dane usunęli) lub o prawie do wniesienia sprzeciwu lub też prawie do przeniesienia Twoich danych do innego podmiotu.</p>
      <p>Zapewniamy, że zabezpieczamy nasze systemy informatyczne aby chronić Twoje dane. Masz prawo uzyskania kopii danych które nam przekazałeś.</p>
      <p>Informujemy, że w związku z powierzeniem nam naszych danych masz prawo do wniesienia skargi do organu nadzorczego, którym jest Generalny Inspektor Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa, tel. 22 531 03 00, fax. 22 531 03 01, e-mail: kancelaria@giodo.gov.pl.</p>

      <h3>Pliki „cookies”</h3>
      <p>Informujemy, iż w celu logowania do naszego Serwisu należącego do 4 Medicine Rek Sp.k. (zwanej dalej „Spółką”) i utrzymania sesji użytkownika używamy informacji zapisywanych przez serwery Serwisu na urządzeniach końcowych użytkownika (komputer, tablet, smartphone etc.), które serwery mogą odczytać przy każdorazowym połączeniu się z tego urządzenia, czyli tzw. „cookies”, Serwery te mogą także używać innych technologii o funkcjach podobnych lub tożsamych z „cookies”.</p>
      <p>„Cookies” to dane informatyczne, w szczególności pliki tekstowe, które poprzez zapisanie w urządzeniu końcowym użytkownika umożliwiają w naszym Systemie logowanie i utrzymanie sesji użytkownika. „Cookies” co do zasady nie identyfikują danych osobowych użytkownika. „Cookies” zawsze zawierają nazwę domeny lub subdomeny Serwisu, z którego pochodzą, czas przechowywania ich na urządzeniu końcowym. „Cookies” mogą być zamieszczane w urządzeniu końcowym użytkownika Serwisu.</p>
      <p>Pliki „cookies” wykorzystywane są dla następujących celów:</p>
      <ul>
        <li>tych nieodłącznie związanych z realizacją i świadczeniem usług w ramach Serwisu;</li>
        <li>administrowania serwisem;</li>
        <li>w celu weryfikacji jakości świadczonych usług drogą elektroniczną;</li>
        <li>do pomiarów statystycznych i udoskonalania usług (cele analityczne).</li>
        <li>tzw. niezbędne pliki „cookies”, umożliwiające korzystanie z usług dostępnych w ramach Serwisów, np. uwierzytelniające pliki „cookies” wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisów,</li>
        <li>pliki „cookies” służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisów,</li>
        <li>pliki „cookies” służące do zliczana statystyk dotyczących stron internetowych i aplikacji.</li>
      </ul>
      <p>W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików „cookies” w urządzeniu końcowym użytkownika.</p>
      <p>Każdy z użytkowników ma możliwość, w każdej chwili, wyłączyć w swoim oprogramowaniu opcję przyjmowania „cookies”. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach przeglądarki internetowej.</p>
      <p>Wyłączenie „cookies” może spowodować utrudnienia czy wręcz uniemożliwić korzystanie z niektórych usług w ramach Serwisu jednakże nie spowoduje to braku możliwości czytania czy oglądania treści zamieszczanych w ramach Serwisu, z wyjątkiem tych, do których dostęp wymaga logowania.</p>
      <p>Wyrażenie zgody przez użytkownika na przyjmowanie „cookies” odbywa się poprzez zmianę ustawień przeglądarki umożliwiających przetwarzanie „cookies” lub poprzez wyrażenie zgody w odpowiednim „oknie” w Serwisie.</p>

      <h3>Informacje dodatkowe</h3>
      <p>Dodatkowe informacje na temat plików cookies i innych technologii można znaleźć m.in. pod adresem wszystkoociasteczkach.pl, youronlinechoices.com lub w sekcji Pomoc w menu przeglądarki internetowej.</p>

      <h3>Dobre praktyki</h3>
      <p>Należy pamiętać, że:</p>
      <ul>
        <li>gdy korzystasz z jednego komputera wspólnie z innymi użytkownikami, po zakończeniu korzystania z Serwisu, do których jesteś zalogowany, koniecznie się wyloguj, aby nikt nie mógł ich podejrzeć;</li>
        <li>dane podane przez Ciebie przy rejestracji znasz tylko Ty i 4 Medicine Rek Sp.k. Z naszej strony gwarantujemy, że nikomu nie zostaną ujawnione, Ty też zachowaj ostrożność w udostępnianiu ich osobom trzecim;</li>
        <li>zdarza się, że osoby korzystające z Sieci chcą &quot;wyciągnąć&quot; od Ciebie informacje na Twój temat i użyć ich przeciwko Tobie bądź innym osobom. Mogą to czynić np. z chęci zysku. Zachowaj ostrożność. Upewnij się, kim jest osoba, która pyta Cię o dane osobowe, jaką reprezentuje instytucję. Pamiętaj, że to właśnie Ciebie chronią stosowne regulacje prawne, w tym dotyczące ochrony danych osobowych.</li>
      </ul>
    </div>
  );
}