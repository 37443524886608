import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import LoginPage from './containers/LoginPage';
import Dashboard from './containers/Dashboard';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <React.Fragment>
      <Route component={App} exact path="/" />
      <Route component={LoginPage} path="/login" />
      <Route component={Dashboard} path="/slots" />
    </React.Fragment>
  </BrowserRouter>,
  document.getElementById('root')
);