import React from 'react';
import { bool, func, string } from 'prop-types';
import { Field } from 'formik';
import styles from './index.scss';

export default function CheckboxField(props) {
  const {
    checked,
    errorName,
    name,
    onOpenModal,
    touched
  } = props;

  return (
    <div className={styles.checkboxContainer}>
      <label className={styles.labelCheckbox}>
        <Field
          checked={checked}
          className={styles.checkbox}
          name={name}
          type="checkbox"
        />
        Akceptuję <span className={styles.btnPolicy} onClick={onOpenModal}>Regulamin i Politykę prywatności</span>

        {errorName && touched ?
          <div className={styles.errorContainer}>
            <span className={styles.error}>
              <i aria-hidden="true" className="fa fa-exclamation-circle" />
            </span>
            <span>{errorName}</span>
          </div>
          : null
        }
      </label>
    </div>
  );
}

CheckboxField.defaultProps = {
  checked: false,
  errorName: null,
  touched: null
};

CheckboxField.propTypes = {
  checked: bool,
  errorName: string,
  name: string.isRequired,
  onOpenModal: func.isRequired,
  touched: bool
};