import axios from 'axios';
import styles from './components/ChartsBox/index.scss';

const session = JSON.parse(localStorage.getItem('session'));
const values = JSON.parse(localStorage.getItem('loginValues'));

const createDataTable = achievements => {
  const { totals: { contributions, fullfilment }, achievements: { publications20172018, monographs } } = achievements;

  const data = {};

  const labelsTranslated = {
    monograph_author: 'Monografie i rozdziały',
    monograph_chapter: 'Monografie i rozdziały',
    publication20172018: 'Publikacje 2017-2018'
  };
  data.slotsShare = {
    labels: Object.keys(contributions).map(value => labelsTranslated[value]),
    series: Object.values(contributions).map(value => ({ className: styles.barColors, value }))
  };

  data.slotsTotal = {
    series: [{ className: styles.donutColor, data: fullfilment.toFixed(0) }, { className: styles.donutColor, data: fullfilment <= 100 && 100 - fullfilment.toFixed(0) }]
  };

  const publicationsData = publications20172018.fullfilment ? publications20172018.fullfilment.toFixed(0) : 0;
  data.piePubication = { series: [{ className: styles.donutColorsPublication, data: publicationsData }, { className: styles.donutColorsPublication, data: publicationsData <= 100 && 100 - publicationsData }] };

  const monographsData = monographs.fullfilment ? monographs.fullfilment.toFixed(0) : 0;
  data.pieMonograph = { series: [{ className: styles.donutColorsMonograph, data: monographsData }, { className: styles.donutColorsMonograph, data: monographsData <= 100 && 100 - monographsData }] };

  data.totalDots = {
      labels: [0, 1, 2, 3, 4],
      series: [{ className: styles.dotsColor, data: [{ x: 1.8, y: 500 }, { x: 4, y: 800 }, { x: 1.6, y: 100 }, { x: 1, y: 230 }, { x: 3.5, y: 670 }, { x: 2.7, y: 80 }, { x: 1.2, y: 350 }, { x: 2.8, y: 120 }, { x: 1.8, y: 120 }, { x: 3.7, y: 530 }, { x: 3, y: 670 }, { x: 0.6, y: 80 } ] }, { className: styles.dotsColor, data: [{ x: 2.8, y: 520 }] }]
    };
  data.totalBars = {
      labels: ['Q1', 'Q2', 'Q3', 'Q4'],
      series: [
        { className: styles.barTotalColors, value: 15 },
        { className: styles.barTotalColorsActive, value: 20 },
        { className: styles.barTotalColors, value: 35 },
        { className: styles.barTotalColors, value: 42 }
      ]
    };

  return { ...achievements, data };
};

export const fetchEmployeesAction = (employees, dispatch) => {
  return dispatch({
    payload: employees,
    type: 'FETCH_EMPLOYEES'
  });
};

export const fetchAchievementsAction = (disciplineId, employeeId, dispatch) => {
  axios.post(`http://${values.department}.sciencewizard.pl/dashboardapi/userData`, `user_id=${session.user_id}&token=${session.token}&employee_id=${employeeId}&discipline_id=${disciplineId}`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
  .then(response => createDataTable(response.data.data))
  .then(achievements => dispatch({
    payload: achievements,
    type: 'FETCH_ACHIEVEMENTS'
  }));
};

export const selectEmployeeAction = (dispatch, employeeId, employees) => {
  const selectedEmployee = employees.filter(el => el.user_id === employeeId)[0];
  const objToSend = {
    selectedEmployee: employeeId,
    disciplines: selectedEmployee.disciplines
  }

  const discipline = selectedEmployee.disciplines && selectedEmployee.disciplines.length && selectedEmployee.disciplines[0].id;

  selectDisciplineAction(discipline, dispatch, employeeId);

  return dispatch({
    payload: objToSend,
    type: 'SELECT_EMPLOYEE'
  });
};

export const selectDisciplineAction = (discipline, dispatch, employeeId) => {
  if (discipline) fetchAchievementsAction(discipline, employeeId, dispatch);

  return dispatch({
    payload: discipline,
    type: 'SELECT_DISCIPLINE'
  });
};