import React, { useReducer } from 'react';

export const Context = React.createContext();

const disciplines = [{ label: 'discipline1', value: '1' }, { label: 'discipline2', value: '2' }];

const initialState = {
  achievements: null,
  disciplines: null,
  employees: [],
  selectedDiscipline: disciplines[0],
  selectedEmployee: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_EMPLOYEES':
      return {
        ...state,
        employees: action.payload,
      };
    case 'FETCH_ACHIEVEMENTS':
      return {
        ...state,
        achievements: action.payload
      };
    case 'SELECT_EMPLOYEE':
      return {
        ...state,
        selectedEmployee: action.payload.selectedEmployee,
        disciplines: action.payload.disciplines
      };
    case 'SELECT_DISCIPLINE':
      return {
        ...state,
        selectedDiscipline: action.payload
      };
    default:
      return state;
  }
};

export function Provider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { dispatch, state };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
}